<template>
  <UiDrawer
    :open="navDrawer.open"
    :side="navDrawer.position"
    v-bind="navDrawer.props"
    variant="blank"
    @close="navDrawer.open = false"
  >
    <AppNav
      :parent-link="navDrawer.key"
      @close="navDrawer.open = false"
    />
  </UiDrawer>
</template>

<script lang="ts" setup>
import UiDrawer from '@ui/components/UiDrawer/UiDrawer.vue'
import { useDrawer } from '../AppDrawer/useDrawer'
import AppNav from '../AppNav/AppNav.vue'

const { navDrawer } = useDrawer()
</script>
